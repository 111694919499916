<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar">
      <el-form-item>
        <el-button @click="add" type="success" size="medium">添加角色</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table :data="gridData" height="650" highlight-current-row v-loading="gridLoading" border class="grid">
      <el-table-column type="index" width="80"></el-table-column>
      <el-table-column prop="name" label="角色名称" width="150"></el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-tooltip effect="dark" placement="bottom-start">
            <div slot="content">
              <p style="max-width:500px;">{{scope.row.description}}</p>
            </div>
            <span>{{sortRemark(scope.row.description)}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status==1 ? 'danger' : 'primary'"
            close-transition
          >{{scope.row.status==1?'禁用':'启用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="small" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>

    <!--添加-->
    <el-dialog title="添加角色" :visible.sync="addDialogVisible" :close-on-click-modal="false">
      <el-form
        :model="addForm"
        label-position="left"
        label-width="auto"
        :rules="inputRule"
        ref="addForm"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="addForm.roleName" auto-complete="off" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :autosize="{minRows: 2,maxRows: 4}"
            v-model="addForm.remark"
            :maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="permissionObj">
          <template v-for="(item,index) in addForm.permissionObj">
            <el-checkbox
              :label="item.id"
              :indeterminate="addForm.permissionObj[index].indeterminate"
              v-model="addForm.permissionObj[index].checked"
              @change="checkAllChange"
              :key=" 'role' + index"
            >{{item.name}}</el-checkbox>
            <el-checkbox-group
              v-model="addForm.permissionObj[index].checkedList"
              :key="index"
              @change="checkItemChange"
              style="margin-left:20px;"
            >
              <el-checkbox
                v-for="child in item.children"
                :key="child.id"
                :label="child.id"
              >{{child.name}}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="addForm.status" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFormSubmit" :loading="addFormLoading">提 交</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改角色"  :visible.sync="editDialogVisible" :close-on-click-modal="false">
      <el-form
        :model="editForm"
        label-position="left"
        label-width="100px"
        :rules="inputRule"
        v-loading="editFormInit"
        ref="editForm"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="editForm.roleName" auto-complete="off" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :autosize="{minRows: 2,maxRows: 4}"
            v-model="editForm.remark"
            :maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="permissionObj">
          <template v-for="(item,index) in editForm.permissionObj">
            <el-checkbox
              :label="item.id"
              :indeterminate="editForm.permissionObj[index].indeterminate"
              v-model="editForm.permissionObj[index].checked"
              @change="checkAllChange2"
              :key="'Role' + index"
            >{{item.name}}</el-checkbox>
            <el-checkbox-group
              v-model="editForm.permissionObj[index].checkedList"
              :key="index"
              @change="checkItemChange2"
              style="margin-left:20px;"
            >
              <el-checkbox
                v-for="child in item.children"
                :key="child.id"
                :label="child.id"
              >{{child.name}}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="editForm.status" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFormSubmit" :loading="editFormLoading">提 交</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>

// import config from "../../common/config";
import api from "../../api/userRole/Role";
export default {
  data() {
    let checkPermission = (rule, value, callback) => {
      let _arr = this.getPermissionIds(value);
      if (_arr.length == 0) {
        callback(new Error("至少选择一项"));
      }
      callback();
    };
    return {
      permissionListInit: false,
      permissionList: [],

      // 分页
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,

      // Grid
      gridLoading: false,
      gridData: [],

      // Form
      addForm: {
        roleName: "",
        remark: "",
        permissionObj: [],
        status: true
      },
      editForm: {
        id: "",
        roleName: "",
        remark: "",
        permissionObj: [],
        status: true
      },
      inputRule: {
        roleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur"
          }
        ],
        permissionObj: [
          {
            validator: checkPermission,
            trigger: "change"
          }
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur"
          }
        ]
      },

      // Dialog
      addDialogVisible: false,
      editDialogVisible: false,
      addFormLoading: false,
      editFormLoading: false,
      editFormInit: false
    };
  },
  methods: {
    sortRemark(str) {
      if (str.length > 20) {
        str = str.substring(0, 20) + "...";
      }
      return str;
    },
    add() {
      this.addDialogVisible = true;
      this.$refs.addForm && this.$refs.addForm.resetFields();
      if (this.permissionListInit) {
        this.addPermissionObjInit();
      } else {
        this.getPermission(() => {
          this.addPermissionObjInit();
        });
      }
    },
    edit(row) {
      this.editDialogVisible = true;
      this.editFormInit = true;
      let _data = { content: { id: row.id } };
      api.editRuleSearch(_data).then(res => {
        this.editFormInit = false;
        this.editForm.id = row.id;
        this.editForm.roleName = row.name;
        this.editForm.remark = row.description;
        this.editPermissionObjInit(res.data.rows);
      });
    },
    checkAllChange() {
      this.checkAllChangeFn(event, "addForm");
    },
    checkAllChange2() {
      this.checkAllChangeFn(event, "editForm");
    },
    checkItemChange(value) {
      this.checkItemChangeFn(value, "addForm");
    },
    checkItemChange2(value) {
      this.checkItemChangeFn(value, "editForm");
    },
    checkAllChangeFn(event, form) {
      for (let i = 0; i < this[form].permissionObj.length; i++) {
        let item = this[form].permissionObj[i];
        if (item.id == event.target.value) {
          item.indeterminate = false;
          item.checkedList = event.target.checked
            ? (() => {
              let _arr = [];
              for (let i = 0; i < item.children.length; i++) {
                _arr.push(item.children[i].id);
              }
              return _arr;
            })()
            : [];
        }
      }
    },
    checkItemChangeFn(value, form) {
      for (let i = 0; i < this[form].permissionObj.length; i++) {
        let item = this[form].permissionObj[i];
        if (item.checkedList.length == item.children.length) {
          item.checked = true;
          item.indeterminate = false;
        } else if (item.checkedList.length == 0) {
          item.checked = false;
          item.indeterminate = false;
        } else {
          item.checked = true;
          item.indeterminate = true;
        }
      }
    },
    getPermissionIds(obj) {
      let _arr = [];
      for (let i = 0; i < obj.length; i++) {
        let item = obj[i];
        if (item.checked) {
          _arr.push(item.id);
          _arr = _arr.concat(item.checkedList);
        }
      }
      return _arr;
    },
    addFormSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addFormLoading = true;
          let _data = {
            content: {
              name: this.addForm.roleName,
              description: this.addForm.remark,
              permissions: this.getPermissionIds(
                this.addForm.permissionObj
              ).join(","),
              status: this.addForm.status ? "0" : "1"
            }
          };
          api.addRule(_data).then(res => {
            if (res.code == "0000") {
              this.addDialogVisible = false;
              this.bindGrid();
              this.$message({
                message: "添加成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    editFormSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.editFormLoading = true;
          let _data = {
            content: {
              id: this.editForm.id,
              name: this.editForm.roleName,
              description: this.editForm.remark,
              permissions: this.getPermissionIds(
                this.editForm.permissionObj
              ).join(","),
              status: this.editForm.status ? "0" : "1"
            }
          };
          api.editRule(_data).then(res => {
            this.editFormLoading = false;
            if (res.code == "0000") {
              this.editDialogVisible = false;
              this.bindGrid();
              this.$message({
                message: "修改成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    getPermission(fn) {
      let _data = {};
      api.searchPermission(_data).then(res => {
        if (res.code == "0000") {
          this.permissionList = res.data.rows;
          this.permissionListInit = true;
          fn && fn();
        }
      });
    },
    bindGrid() {
      this.gridLoading = true;
      let _data = {
        content: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize
        }
      };
      api.searchRole(_data).then(res => {
        if (res.code == "0000") {
          this.gridData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.gridLoading = false;
      });
    },
    addPermissionObjInit() {
      let _temp = [];
      for (let i = 0; i < this.permissionList.length; i++) {
        _temp.push(
          Object.assign(
            {
              checkedList: [],
              checked: false,
              indeterminate: false
            },
            this.permissionList[i]
          )
        );
      }
      this.addForm.permissionObj = _temp;
    },
    editPermissionObjInit(list) {
      let _temp = [];
      for (let i = 0; i < list.length; i++) {
        _temp.push(Object.assign(_getTempObj(list[i]), list[i]));
      }

      function _getTempObj(list) {
        if (list.checked == false) {
          return {
            checkedList: [],
            indeterminate: false
          };
        } else {
          let _arr = [];
          for (let i = 0; i < list.children.length; i++) {
            if (list.children[i].checked) {
              _arr.push(list.children[i].id);
            }
          }
          return {
            checkedList: _arr,
            indeterminate: _arr.length == list.children.length ? false : true
          };
        }
      }
      this.editForm.permissionObj = _temp;
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    }
  },
  mounted: function() {
    this.bindGrid();
  }
};
</script>

import request from "@/utils/http/requset";

// 修改角色查询
function editRuleSearch(data){
  return request({
    url: "manage/permission/role",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 添加角色
function addRule(data){
  return request({
    url: "manage/role/create",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 修改角色
function editRule(data){
  return request({
    url: "manage/role/update",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 权限查询
function searchPermission(data){
  return request({
    url: "manage/permission/search",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 角色列表查询
function searchRole(data){
  return request({
    url: "manage/role/search",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

export default {
  editRuleSearch,
  addRule,
  editRule,
  searchPermission,
  searchRole
};
